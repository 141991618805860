import React, { useState } from "react";
import { FaGoogle } from 'react-icons/fa'; 
import './Register.css'; 

const Register = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    username: "",
    email: "",
    password: "",
    confirmPassword: "",
    termsAgreed: false,
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission logic here
    console.log(formData);
  };

  return (
    <div className="login-container">
      <div className="form-section">
        <h1 className="heading">Join Stockin Dashboard</h1>
        <h3 className="sub-heading">Sign Up For Free</h3>

        {/* Add a wrapper for form to control the width */}
        <div className="form-wrapper">
          <form onSubmit={handleSubmit}>
            <div className="form-row">
              <input
                type="text"
                name="firstName"
                placeholder="First Name"
                value={formData.firstName}
                onChange={handleChange}
                className="input-half"
              />
              <input
                type="text"
                name="lastName"
                placeholder="Last Name"
                value={formData.lastName}
                onChange={handleChange}
                className="input-half"
              />
            </div>

            <input
              type="text"
              name="username"
              placeholder="Username"
              value={formData.username}
              onChange={handleChange}
              className="input-full"
            />

            <input
              type="email"
              name="email"
              placeholder="Email"
              value={formData.email}
              onChange={handleChange}
              className="input-full"
            />

            <input
              type="password"
              name="password"
              placeholder="Password"
              value={formData.password}
              onChange={handleChange}
              className="input-full"
            />

            <input
              type="password"
              name="confirmPassword"
              placeholder="Confirm Password"
              value={formData.confirmPassword}
              onChange={handleChange}
              className="input-full"
            />

            <div className="checkbox-wrapper">
              <input
                type="checkbox"
                name="termsAgreed"
                checked={formData.termsAgreed}
                onChange={handleChange}
                className="checkbox"
              />
              <label className="checkbox-label">
                I agree to all Terms, Privacy Policy, and fees
              </label>
            </div>

            <div className="submit-button-wrapper">
              <button type="submit" className="submit-button">
                Get Started
              </button>
            </div>
          </form>
        </div>

        <div className="or-section">OR</div>

        <div className="social-buttons-wrapper">
            <button className="social-button google-button">
                <FaGoogle className="google-icon" style={{ marginRight: '10px' }} />
                Sign in with Google
            </button>
        </div>
        <div className="account-footer">
          Already have an account? <a href="/signin">Login</a>
        </div>
      </div>

      <div className="image-section">
      </div>
    </div>
  );
};

export default Register;
