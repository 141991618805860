import React, { useContext } from 'react';
import { ThemeContext } from '../../theme-context';
import { FaWallet, FaCreditCard } from 'react-icons/fa'; 
import './Wallet.css'; 

const Wallet = () => {
  const { theme } = useContext(ThemeContext);

  return (
    <div className={`wallet-container ${theme}`}>
      <div className="wallet-main">
        <div className="watchlist">
          <h2>Wallet</h2>
          <p>Update 20/07/2024 at 04:30 PM</p>
          <div className="wallet-balance">
            <FaWallet />
            <span>Wallet Balance</span>
            <h1>$201.01</h1>
          </div>
          <div className="investment-info">
            <div className="info-box blue">
              <p>Total Investment</p>
              <h3>$201.01</h3>
            </div>
            <div className="info-box yellow">
              <p>Total Return</p>
              <h3>$201.01</h3>
            </div>
            <div className="info-box green">
              <p>All Time Profit / Loss</p>
              <h3>$201.01</h3>
            </div>
          </div>
        </div>

        {/* Transaction History Section */}
        <div className="transaction-history">
          <h2>Transaction History</h2>
          <p>Update 20/07/2024 at 04:30 PM</p>
          <table>
            <thead>
              <tr>
                <th className="invoice">Invoice</th>
                <th className="amount">Amount</th>
                <th className="hide-on-mobile">Date</th> 
                <th className="status">Status</th>
                <th className="hide-on-mobile">Manage</th> 
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="invoice">Invoice#0089-Dec-Sep 2024</td>
                <td className="amount">$14,000</td>
                <td className="hide-on-mobile">Jun-06-24</td>
                <td className="status">Success</td>
                <td className="hide-on-mobile">Download</td> 
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      {/* Sidebar Section */}
      <div className="wallet-sidebar">
        <div className="coin-inputs">
          <label>Coin</label>
          <input type="text" defaultValue="$2.00" />
          <FaCreditCard />
        </div>
        <form>
          <label>First Name</label>
          <input type="text" placeholder="Enter First Name" />
          <label>Expiration</label>
          <input type="text" placeholder="MM/YY" />
          <label>CVV</label>
          <input type="text" placeholder="123" />
          <label>Email</label>
          <input type="email" placeholder="Enter your email" />
          <button type="submit">Save</button>
        </form>
      </div>
    </div>
  );
};

export default Wallet;
