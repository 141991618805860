import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { ThemeContext } from '../../theme-context';
import './Sidebar.css'; 

const Sidebar = ({ isOpen, onToggle }) => {
  const { theme } = useContext(ThemeContext);
  const navigate = useNavigate(); 

  // Function to handle navigation
  const handleNavigation = (path) => {
    navigate(path);
    onToggle(); 
  };

  return (
    <div className={`sidebar ${theme} ${isOpen ? 'open' : ''}`}>
      <div className="sidebar-header">
        <span className="sidebar-logo">🔶</span>
        <span className="sidebar-title">Smart Capital</span>
        <span className="close-icon" onClick={onToggle} aria-label="Close sidebar">
          ❌
        </span>
      </div>
      <ul className="sidebar-menu">
        <li className="sidebar-item" onClick={() => handleNavigation('/dashboard')}>
          <span className="sidebar-icon">📊</span> Dashboard
        </li>
        {/* <li className="sidebar-item" onClick={() => handleNavigation('/Stock')}>
          <span className="sidebar-icon">📈</span> Stock
        </li> */}
        <li className="sidebar-item" onClick={() => handleNavigation('/StockTable')}>
          <span className="sidebar-icon">📈</span>  Stock
        </li>
        <li className="sidebar-item" onClick={() => handleNavigation('/favorite')}>
          <span className="sidebar-icon">🔖</span> Favorite
        </li>
        {/* <li className="sidebar-item" onClick={() => handleNavigation('/StockChart')}>
          <span className="sidebar-icon">🔖</span> Stock chart
        </li> */}
        <li className="sidebar-item" onClick={() => handleNavigation('/wallet')}>
          <span className="sidebar-icon">💼</span> Wallets
        </li>
        <div className="sidebar-section">Account</div>
        <li className="sidebar-item" onClick={() => handleNavigation('/community')}>
          <span className="sidebar-icon">👥</span> Our community
        </li>
        <li className="sidebar-item" onClick={() => handleNavigation('/Profile')}>
          <span className="sidebar-icon">👤</span> Profile
        </li>
        <li className="sidebar-item" onClick={() => handleNavigation('/contact')}>
          <span className="sidebar-icon">✉️</span> Contact Us
        </li>
        <li className="sidebar-item" onClick={() => handleNavigation('/logout')}>
          <span className="sidebar-icon">↩️</span> Logout
        </li>
      </ul>
    </div>
  );
};

export default Sidebar;
