import React, { useContext } from 'react';
import { ThemeContext } from '../../theme-context';
import { FaFacebook, FaInstagram, FaLinkedin } from 'react-icons/fa'; 
import './Footer.css';

const Footer = () => {
  const { theme } = useContext(ThemeContext);

  return (
    <footer className={`footer-container ${theme}`}>
      <div className="footer-content">
        {/* Left Section */}
        <div className="footer-branding">
          <img
            src="https://via.placeholder.com/50" 
            alt="🔶"
            className="footer-logo"
          />
          <h3>Smart Capital</h3>
          <p>
            Ease of shopping is our main focus. With powerful search features and customizable filters,
            you can easily find the products you are looking for.
          </p>
          <div className="social-icons">
            <a href="#"><FaFacebook /></a>
            <a href="#"><FaInstagram /></a>
            <a href="#"><FaLinkedin /></a>
          </div>
        </div>

        {/* Middle Sections */}
        <div className="footer-links">
          <h4>Get Started</h4>
          <ul>
            <li><a href="#">Service</a></li>
            <li><a href="#">Contact Us</a></li>
            <li><a href="#">About Us</a></li>
          </ul>
        </div>

        {/* <div className="footer-links">
          <h4>Resources</h4>
          <ul>
            <li><a href="#">Dashboard</a></li>
            <li><a href="#">Workout Library</a></li>
          </ul>
        </div> */}

        <div className="footer-links">
          <h4>About</h4>
          <ul>
            <li><a href="#">Dashboard</a></li>
            <li><a href="#">About Us</a></li>
          </ul>
        </div>        
      </div>

      {/* Bottom Section */}
      <div className="footer-bottom">
        <p>© 2024 YTC</p>
      </div>
    </footer>
  );
};

export default Footer;
