import React, { useContext } from 'react';
import { ThemeContext } from '../../theme-context';
import './Profile.css';

const Profile = () => {
  const { theme } = useContext(ThemeContext);

  return (
    <div className={`profile-page ${theme}`}>
      <div className="profile-container">
        {/* Left Section - Account Settings Form */}
        <div className="account-card">
          <h2>Account Setting</h2>
          <a href="#" className="profile-link">My Profile</a>
          <p>Update personal details and cover photo here</p>
          <hr className="divider" />

          <div className="photo-section">
            <div className="profile-photo">
              <p>Your Photo</p>
              <img src="https://via.placeholder.com/100" alt="Profile" className="profile-image" />
            </div>
            <div className="cover-image">
              <p>Cover Image</p>
              <div className="image-placeholder">
                <p>Drag and drop your image here</p>
              </div>
            </div>
          </div>

          <div className="general-info">
            <h4>General Information</h4>
            <form>
              <div className="form-row">
                <div className="form-group">
                  <label>First Name</label>
                  <input type="text" placeholder="First Name" />
                </div>
                <div className="form-group">
                  <label>Last Name</label>
                  <input type="text" placeholder="Last Name" />
                </div>
              </div>
              <div className="form-group">
                <label>Display Name</label>
                <input type="text" placeholder="Display Name" />
              </div>
              <div className="form-group">
                <label>Email</label>
                <input type="email" placeholder="Email" />
              </div>
              <div className="form-row">
                <div className="form-group">
                  <label>Select Country</label>
                  <select>
                    <option>+91</option>
                    {/* More country options */}
                  </select>
                </div>
                <div className="form-group">
                  <label>Phone Number</label>
                  <input type="tel" placeholder="Phone Number" />
                </div>
              </div>
              <div className="form-group">
                <label>Bio</label>
                <textarea placeholder="Description"></textarea>
              </div>
              <div className="form-actions">
                <button type="button" className="discard-btn">Discard Change</button>
                <button type="submit" className="save-btn">Save</button>
              </div>
            </form>
          </div>
        </div>

        <div className="profile-card">
          <div className="profile-card-header">
            <img src="https://via.placeholder.com/150" alt="Profile" className="profile-image" />
          </div>
          <div className="profile-card-body">
            <h4>John Marker UI</h4>
            <p>Aug 23, 2024. Aceh, Indonesia</p>
            <p>Lorem ipsum is simply dummy text of the printing and typesetting industry.</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
