import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { TrendingUp, ShowChart, Security } from '@mui/icons-material';
import { motion } from 'framer-motion';

const theme = createTheme({
  palette: {
    primary: {
      main: '#4F46E5',
    },
    secondary: {
      main: '#06B6D4',
    },
  },
  typography: {
    fontFamily: 'Inter, sans-serif',
  },
});

const CapitalIndiaLogo = () => (
  <svg width="40" height="40" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="100" height="100" rx="20" fill="url(#logo-gradient)" />
    <path d="M20 80V20H40V80H20Z" fill="#FFFFFF" />
    <path d="M50 80V20H70L80 50L70 80H50Z" fill="#FFFFFF" />
    <defs>
      <linearGradient id="logo-gradient" x1="0" y1="0" x2="100" y2="100" gradientUnits="userSpaceOnUse">
        <stop stopColor="#4F46E5" />
        <stop offset="1" stopColor="#06B6D4" />
      </linearGradient>
    </defs>
  </svg>
);

export default function MUILandingPage() {
  const navigate = useNavigate(); 

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Box sx={{ 
        minHeight: '100vh', 
        background: 'linear-gradient(to bottom right, #EEF2FF, #E0F2FE, #CCFBF1)' 
      }}>
        <AppBar position="sticky" color="transparent" elevation={0} sx={{ backdropFilter: 'blur(10px)' }}>
          <Toolbar>
            <motion.div
              initial={{ y: -100 }}
              animate={{ y: 0 }}
              transition={{ type: "spring", stiffness: 100 }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <CapitalIndiaLogo />
                <Typography variant="h6" component="div" sx={{ ml: 1, fontWeight: 'bold', background: 'linear-gradient(to right, #4F46E5, #06B6D4)', WebkitBackgroundClip: 'text', WebkitTextFillColor: 'transparent' }}>
                  Smart Capital
                </Typography>
              </Box>
            </motion.div>
            <Box sx={{ flexGrow: 1 }} />
            <Box sx={{ display: { xs: 'none', md: 'flex' }, gap: 2 }}>
              <Button variant="outlined" color="primary" onClick={() => navigate('/signin')}>Sign In</Button>
              <Button variant="contained" sx={{ background: 'linear-gradient(to right, #4F46E5, #06B6D4)' }} onClick={() => navigate('/signup')}>Sign Up</Button>
            </Box>
          </Toolbar>
        </AppBar>

        <Container maxWidth="lg">
          <Box sx={{ my: 8, textAlign: 'center' }}>
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8 }}
            >
              <Typography variant="h2" component="h1" gutterBottom sx={{ fontWeight: 'bold', background: 'linear-gradient(to right, #4F46E5, #7C3AED, #06B6D4)', WebkitBackgroundClip: 'text', WebkitTextFillColor: 'transparent' }}>
                Trade Smarter with Smart Capital 
              </Typography>
              <Typography variant="h5" sx={{ mb: 4, color: 'text.secondary' }}>
                Experience the power of advanced analytics and real-time market data. Start trading with confidence today.
              </Typography>
              <Box sx={{ '& > :not(style)': { m: 1 } }}>
                <Button variant="contained" size="large" sx={{ background: 'linear-gradient(to right, #4F46E5, #06B6D4)' }} onClick={() => navigate('/get-started')}>
                  Get Started
                </Button>
                <Button variant="outlined" size="large" color="primary" onClick={() => navigate('/learn-more')}>
                  Learn More
                </Button>
              </Box>
            </motion.div>
          </Box>

          <Box sx={{ my: 8 }}>
            <Typography variant="h3" component="h2" align="center" gutterBottom sx={{ fontWeight: 'bold', background: 'linear-gradient(to right, #4F46E5, #06B6D4)', WebkitBackgroundClip: 'text', WebkitTextFillColor: 'transparent' }}>
              Why Choose Smart Capital
            </Typography>
            <Grid container spacing={4} sx={{ mt: 4 }}>
              {[
                { icon: TrendingUp, title: 'Advanced Analytics', description: 'Make informed decisions with our cutting-edge market analysis tools.' },
                { icon: ShowChart, title: 'Real-Time Data', description: 'Stay ahead with up-to-the-second market information and current trends.' },
                { icon: Security, title: 'Secure Trading', description: 'Trade with confidence knowing your investments are protected by  security.' },
              ].map((feature, index) => (
                <Grid item xs={12} md={4} key={index}>
                  <motion.div whileHover={{ scale: 1.05, rotate: index % 2 === 0 ? 2 : -2 }}>
                    <Card sx={{ height: '100%', background: 'linear-gradient(to bottom right, #F0F9FF, #E0F2FE)' }}>
                      <CardContent sx={{ textAlign: 'center' }}>
                        <feature.icon sx={{ fontSize: 60, color: 'primary.main', mb: 2 }} />
                        <Typography variant="h5" component="div" gutterBottom>
                          {feature.title}
                        </Typography>
                        <Typography variant="body1" color="text.secondary">
                          {feature.description}
                        </Typography>
                      </CardContent>
                    </Card>
                  </motion.div>
                </Grid>
              ))}
            </Grid>
          </Box>
        </Container>

        <Box sx={{ py: 8, background: 'linear-gradient(to right, #4F46E5, #06B6D4)' }}>
          <Container maxWidth="md">
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8 }}
              viewport={{ once: true }}
            >
              <Typography variant="h3" align="center" gutterBottom sx={{ color: 'white', fontWeight: 'bold' }}>
                Start Trading Today
              </Typography>
              <Typography variant="h6" align="center" paragraph sx={{ color: 'rgba(255, 255, 255, 0.8)' }}>
                Join thousands of successful traders on India's most advanced trading platform.
              </Typography>
              <Box sx={{ textAlign: 'center', mt: 4 }}>
                <Button variant="contained" size="large" sx={{ backgroundColor: 'white', color: 'primary.main', '&:hover': { backgroundColor: 'rgba(255, 255, 255, 0.9)' } }} onClick={() => navigate('/open-account')}>
                  Open an Account
                </Button>
              </Box>
            </motion.div>
          </Container>
        </Box>

        <Box component="footer" sx={{ py: 3, px: 2, mt: 'auto', backgroundColor: 'rgba(255, 255, 255, 0.8)', backdropFilter: 'blur(10px)' }}>
          <Container maxWidth="lg">
            <Grid container justifyContent="space-between" alignItems="center">
              <Grid item>
                <Typography variant="body2" color="text.secondary">
                  © 2024 Smart Capital. All rights reserved.
                </Typography>
              </Grid>
              <Grid item>
                <Box sx={{ display: 'flex', gap: 2 }}>
                  {['Terms of Service', 'Privacy Policy', 'Contact Us'].map((item) => (
                    <a key={item} href="#" style={{ textDecoration: 'none' }}>
                      <Typography component="span" variant="body2" sx={{ color: 'text.secondary', '&:hover': { textDecoration: 'underline' } }}>
                        {item}
                      </Typography>
                    </a>
                  ))}
                </Box>
              </Grid>
            </Grid>
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  );
}

