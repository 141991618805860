import React, { useContext } from "react";
import { ThemeContext } from "../../theme-context";
import { FaSearch, FaEnvelope, FaBell, FaUserCircle, FaBars, FaSun, FaMoon } from "react-icons/fa"; 
import "./TopBar.css"; 

const TopBar = ({ onToggle }) => {
  const { theme, setTheme } = useContext(ThemeContext);

  const toggleTheme = () => {
    setTheme(prevTheme => (prevTheme === "dark" ? "light" : "dark"));
  };

  return (
    <div className={`topbar ${theme === "dark" ? "dark-theme" : "light-theme"}`}>
      <FaBars className="topbar-icon mobile-toggle" onClick={onToggle} aria-label="Open sidebar" />
      <div className="topbar-left">
        <div className="input-container">
          <FaSearch className="search-icon" />
          <input
            type="text"
            className="email-input"
            placeholder="Enter Your Email Here"
          />
        </div>
      </div>
      <div className="topbar-right">
        <div className="theme-toggle" onClick={toggleTheme} aria-label="Toggle theme">
          {theme === "dark" ? <FaSun className="topbar-icon" /> : <FaMoon className="topbar-icon" />}
        </div>
        <FaEnvelope className="topbar-icon" />
        <FaBell className="topbar-icon" />
        <FaUserCircle className="topbar-icon user-icon" />
      </div>
    </div>
  );
};

export default TopBar;
