import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  IconButton,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Skeleton,
} from "@mui/material";
import { ThemeContext } from "../../theme-context";
import axios from "axios";
import './Stock.css';
import Cookies from "js-cookie";
import { useNavigate } from 'react-router-dom';

const Stock = () => {
  const { theme } = useContext(ThemeContext);
  const [currentCardIndex, setCurrentCardIndex] = useState(0);
  const [stockData, setStockData] = useState([]);
  const [socketData, setSocketData] = useState({});
  const [loadingGainersLosers, setLoadingGainersLosers] = useState(true);
  const [loadingActiveStocks, setLoadingActiveStocks] = useState(true);
  const [topGainers, setTopGainers] = useState([]);
  const [topLosers, setTopLosers] = useState([]);
  const [mostActiveStocks, setMostActiveStocks] = useState([]);
  const navigate = useNavigate();


  const cardWidth = 220;
  const cardHeight = 80;

  const colors = {
    primary: {
      400: `var(--primary-${theme}-400)`,
      500: `var(--primary-${theme}-500)`,
    },
    greenAccent: {
      500: `var(--greenAccent-${theme}-500)`,
      600: `var(--greenAccent-${theme}-600)`,
    },
    redAccent: {
      500: `var(--red-${theme}-500)`,
    },
    grey: {
      100: `var(--grey-${theme}-100)`,
      300: `var(--grey-${theme}-300)`,
    },
  };

  const fetchTopGainersAndLosers = async () => {
    setLoadingGainersLosers(true);
    const accessToken = Cookies.get('accessToken');
  
    if (!accessToken) {
      console.error('Access token not found in sessionStorage for TopGainer and TopLooser api');
      return;
    }
    try {
      const response = await axios.get('https://backend.smartcapital.co.in/stock/topLoserAndGainer', {
        headers: {
          'access-token': accessToken,
        },
      });
      const { topGainers, topLosers } = response.data;
      setTopGainers(topGainers.slice(0, 5));
      setTopLosers(topLosers.slice(0, 5));
    } catch (error) {
      console.error('Error fetching top gainers and losers:', error);
      // setError('Failed to fetch top gainers and losers.');
    } finally {
      setLoadingGainersLosers(false);
    }
  };

  const fetchStockData = async () => {
    // setLoading(true);
    try {
      const response = await axios.get('https://backend.smartcapital.co.in/admin/getDashCard');
      const formattedData = response.data.dashboardData.map(stock => ({
        name: stock.name || stock.tradingsymbol || "Loading...",
        instrument_token: stock.instrument_token,
        last_price: stock.last_price !== null ? stock.last_price : 0,
        net_change: stock.net_change !== null ? stock.net_change : 0,
      }));
      setStockData(formattedData);
    } catch (error) {
      console.error('Error fetching stock data:', error);
      // setError('Failed to fetch stock data.');
    } finally {
      // setLoading(false);
    }
  };

  const fetchMostActiveStocks = async () => {
    setLoadingActiveStocks(true);
    const accessToken = Cookies.get('accessToken');
    
    if (!accessToken) {
      console.error('Access token not found in sessionStorage');
      return;
    }
    try {
      const response = await axios.get('https://backend.smartcapital.co.in/stock/mostActiveStock', {
        headers: {
          'access-token': accessToken,
        },
      });
      const { mostActiveStocks } = response.data;
      setMostActiveStocks(mostActiveStocks.slice(0, 11));
    } catch (error) {
      // console.error('Error fetching most active stocks:', error);
      // setError('Failed to fetch most active stocks.');
    } finally {
      setLoadingActiveStocks(false);
    }
  };

  useEffect(() => {
  const fetchStockDataAfterToken = () => {
    // console.log('Access Token received, now making API calls...');
    
    fetchTopGainersAndLosers();
    fetchStockData();
    fetchMostActiveStocks();
  };

  const api_key = 'oe0zgbgzlbuqdmra';
  const wsUrl = `wss://real-time.smartcapital.co.in?api_key=${api_key}`;
  const ws = new WebSocket(wsUrl);

  ws.onmessage = (event) => {
    const data = JSON.parse(event.data);

    if (data.message) {
      console.log('WebSocket Message:', data.message);
      const accessToken = data.accessToken;
      
      Cookies.set('accessToken', accessToken);
      // console.log('Access Token stored in cookies:', accessToken);

      fetchStockDataAfterToken(accessToken);

    }

    if (Array.isArray(data) && data.length > 0) {
      data.forEach(stockUpdate => {
        const { instrument_token, last_price, change, ohlc } = stockUpdate;
        setSocketData((prevSocketData) => ({
          ...prevSocketData,
          [instrument_token]: {
            last_price,
            net_change: change,
            ohlc,
          },
        }));
      });
    } else {
      // console.error('Received data is not an array or is empty:', data);
    }
  };

  ws.onerror = (error) => {
    console.error('WebSocket Error:', error);
  };

  return () => {
    // Clean up the WebSocket connection when the component is unmounted
    // ws.close();
  };
}, []);


  const renderShimmerRows = () => (
    [...Array(5)].map((_, index) => (
      <TableRow key={index}>
        <TableCell>
          <Skeleton variant="text" width="80%" height={40} />
        </TableCell>
        <TableCell>
          <Skeleton variant="text" width="80%" height={40} />
        </TableCell>
        <TableCell>
          <Skeleton variant="text" width="80%" height={40} />
        </TableCell>
        <TableCell>
          <Skeleton variant="text" width="80%" height={40} />
        </TableCell>
      </TableRow>
    ))
  );

  const handleScrollLeft = () => {
    setCurrentCardIndex((prevIndex) => Math.max(prevIndex - 1, 0));
  };

  const handleScrollRight = () => {
    setCurrentCardIndex((prevIndex) => Math.min(prevIndex + 1, stockData.length - 1));
  };

  const getTextColor = (netChange) => {
    return String(netChange).startsWith("-") ? colors.redAccent[500] : colors.greenAccent[500];
  };

  const formatNetChange = (netChange) => {
    return `${parseFloat(netChange).toFixed(2)}%`;
  };

  const calculateValueChange = (lastPrice, netChange) => {
    return ((lastPrice * netChange) / 100).toFixed(2);
  };

  return (
     <Box m="20px">

      <Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gridAutoRows="auto" gap="20px">
        {/* ROW 1 */}
        <Box gridColumn="span 12" display="flex" alignItems="center" justifyContent="space-between" mb={2} mt={4}>
            <IconButton 
              onClick={handleScrollLeft} 
              disabled={currentCardIndex === 0} 
              sx={{ marginRight: '5px', color: theme === "light" ? "black" : "white" }}
            >
              <span>&lt;</span>
            </IconButton>

            <Box display="flex" overflow="hidden" sx={{ width: 'calc(100% - 60px)' }}>
              <Box display="flex" transition="transform 0.3s ease" style={{ transform: `translateX(-${currentCardIndex * (cardWidth + 20)}px)` }}>
                {stockData.map((stock, index) => {
                  const updatedStock = socketData[stock.instrument_token] || {};
                  const lastPrice = updatedStock.last_price !== undefined ? updatedStock.last_price : stock.last_price;
                  const netChange = updatedStock.net_change !== undefined ? updatedStock.net_change : stock.net_change;
                  const valueChange = calculateValueChange(lastPrice, netChange);
                  const isIncrease = netChange > 0;

                  return (
                    <Box 
                      key={index} 
                      mx={1} 
                      backgroundColor={colors.primary[400]} 
                      display="flex" 
                      flexDirection="column" 
                      alignItems="flex-start" 
                      justifyContent="center" 
                      flexShrink={0} 
                      width={cardWidth} 
                      p={2} 
                      borderRadius={2} 
                      height={cardHeight} 
                      sx={{ border: '1px solid grey', cursor: 'pointer' }} 
                      onClick={() => navigate(`/StockChart/${stock.instrument_token}`)}
                    >
                      <Typography variant="h6" fontWeight="600" color={colors.grey[100]}>
                        {stock.name}
                      </Typography>
                      <Box display="flex" justifyContent="space-between" width="100%" my={1}>
                        <Typography variant="body1" color={theme === "light" ? "black" : "white"}>
                          {lastPrice} 
                          <span style={{ 
                            color: isIncrease ? colors.greenAccent[500] : colors.redAccent[500], 
                            marginLeft: '5px' 
                          }}>
                            ({isIncrease ? `↑ +${valueChange}` : `↓ ${valueChange}`})
                          </span>
                        </Typography>
                        <Typography 
                          variant="body1" 
                          color={getTextColor(netChange)}
                        >
                          {formatNetChange(netChange)}
                        </Typography>
                      </Box>
                    </Box>
                  );
                })}
              </Box>
            </Box>

            <IconButton 
              onClick={handleScrollRight} 
              disabled={currentCardIndex === stockData.length - 1} 
              sx={{ marginLeft: '5px', color: theme === "light" ? "black" : "white" }}
            >
              <span>&gt;</span>
            </IconButton>
          </Box>


        {/* ROW 2 - Top Gainers and Losers */}
        <Box gridColumn="span 8" display="flex" flexDirection="column" gap={2}>
          <Box backgroundColor={colors.primary[400]} p={2} borderRadius={2}>
            <Typography color={colors.grey[100]} variant="h5" fontWeight="600">Top Gainers</Typography>
            <TableContainer component={Paper} elevation={0} sx={{ backgroundColor: 'transparent', boxShadow: 'none', border: 'none' }}>
              <Table>
                <TableHead>
                  <TableRow >
                    <TableCell sx={{ color: theme === "light" ? "black" : "white" }}><strong>Trading Symbol</strong></TableCell>
                    <TableCell sx={{ color: theme === "light" ? "black" : "white" }}><strong>Company Name</strong></TableCell>
                    <TableCell sx={{ color: theme === "light" ? "black" : "white" }}><strong>Current Price</strong></TableCell>
                    <TableCell sx={{ color: theme === "light" ? "black" : "white" }}><strong>Percentage </strong></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {loadingGainersLosers ? renderShimmerRows() : topGainers.map((stock, index) => (
                    <TableRow key={index}
                    sx={{ cursor: 'pointer' }} 
                    onClick={() => navigate(`/StockChart/${stock.instrument_token}`)}
                    >
                      <TableCell sx={{ color: theme === "light" ? "black" : "white" }}>{stock.tradingsymbol}</TableCell>
                      <TableCell sx={{ color: theme === "light" ? "black" : "white" }}>{stock.instrument_name}</TableCell>
                      <TableCell sx={{ color: theme === "light" ? "black" : "white" }}>{stock.last_price}</TableCell>
                      <TableCell sx={{ color: getTextColor(stock.percentageChange) }}>
                        {formatNetChange(stock.percentageChange)}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>

          <Box backgroundColor={colors.primary[400]} p={2} borderRadius={2}>
            <Typography color={colors.grey[100]} variant="h5" fontWeight="600">Top Losers</Typography>
            <TableContainer component={Paper} elevation={0} sx={{ backgroundColor: 'transparent', boxShadow: 'none', border: 'none' }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ color: theme === "light" ? "black" : "white" }}><strong>Trading Symbol</strong></TableCell>
                    <TableCell sx={{ color: theme === "light" ? "black" : "white" }}><strong>Company  Name</strong></TableCell>
                    <TableCell sx={{ color: theme === "light" ? "black" : "white" }}><strong>Current Price</strong></TableCell>
                    <TableCell sx={{ color: theme === "light" ? "black" : "white" }}><strong>Percentage</strong></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {loadingGainersLosers ? renderShimmerRows() : topLosers.map((stock, index) => (
                    <TableRow key={index}
                    sx={{ cursor: 'pointer' }} 
                    onClick={() => navigate(`/StockChart/${stock.instrument_token}`)}>
                      <TableCell sx={{ color: theme === "light" ? "black" : "white" }}>{stock.tradingsymbol}</TableCell>
                      <TableCell sx={{ color: theme === "light" ? "black" : "white" }}>{stock.instrument_name}</TableCell>
                      <TableCell sx={{ color: theme === "light" ? "black" : "white" }}>{stock.last_price}</TableCell>
                      <TableCell sx={{ color: getTextColor(stock.percentageChange) }}>
                        {formatNetChange(stock.percentageChange)}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Box>

        {/* Active Stocks */}
        <Box gridColumn="span 4" backgroundColor={colors.primary[400]} overflow="auto" height="auto" p={2}>
          <Box display="flex" alignItems="center" borderBottom={`4px solid ${colors.primary[500]}`} colors={colors.grey[100]} p="7px">
            <Typography color={colors.grey[100]} variant="h5" fontWeight="600">
              Active Stocks
            </Typography>
          </Box>
          <TableContainer component={Paper} elevation={0} sx={{ backgroundColor: 'transparent', boxShadow: 'none', border: 'none' }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ color: theme === "light" ? "black" : "white" }}><strong>Company Name</strong></TableCell>
                  <TableCell sx={{ color: theme === "light" ? "black" : "white" }}><strong> Price</strong></TableCell>
                  <TableCell sx={{ color: theme === "light" ? "black" : "white" }}><strong>Volume</strong></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {loadingActiveStocks ? renderShimmerRows() : mostActiveStocks.map((stock, index) => (
                  <TableRow key={index}
                  sx={{ cursor: 'pointer' }} 
                  onClick={() => navigate(`/StockChart/${stock.instrument_token}`)}>
                    <TableCell sx={{ color: theme === "light" ? "black" : "white" }}>{stock.instrument_name}</TableCell>
                    <TableCell sx={{ color: theme === "light" ? "black" : "white" }}>{stock.last_price}</TableCell>
                    <TableCell sx={{ color: theme === "light" ? "black" : "white" }}>{stock.volume}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
    </Box>
  );
};

export default Stock;
