import React, { useState } from "react";
import { FaGoogle} from 'react-icons/fa'; 
import './Login.css';
import { useNavigate } from "react-router-dom"; 

const Login = () => {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle login submission logic here
    console.log(formData);
    navigate('/Dashboard');
  };

  return (
    <div className="login-container">
      <div className="form-section" >
        <h1 className="heading">Welcome Back to Stockin Dashboard</h1>
        <h3 className="sub-heading">Log In to Your Account</h3>

        <form onSubmit={handleSubmit} className="form-fields">
          <input
            type="email"
            name="email"
            placeholder="Email"
            value={formData.email}
            onChange={handleChange}
            className="input-full-label"
          />

          <input
            type="password"
            name="password"
            placeholder="Password"
            value={formData.password}
            onChange={handleChange}
            className="input-full-label"
          />

          <button type="submit" className="submit-button-login">
            Log In
          </button>
        </form>

        <div className="or-section">OR</div>

        <div className="social-button-login">
            <button className="social-button google-button">
                <FaGoogle className="google-icon" style={{ marginRight: '10px' }} />
                Sign in with Google
            </button>
        </div>
        <div className="account-footer">
          Don't have an account? <a href="/signup">Sign Up</a>
        </div>
      </div>

      <div className="image-section">
      </div>
    </div>
  );
};

export default Login;
