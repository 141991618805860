import React, { useContext } from 'react';
import { Line } from 'react-chartjs-2';
import { ThemeContext } from '../../theme-context';
import { FaApple, FaGoogle } from 'react-icons/fa';
import './Watchlist.css';

import { Chart, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';

Chart.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const stockData = [
  {
    name: 'AAPL',
    company: 'Apple Inc',
    price: '$201.01',
    valueChange: '+201.01',
    balance: '$201.01',
    chartData: [20, 40, 30, 50, 70, 60, 80],
    trend: 'up',
  },
  {
    name: 'GOOGL',
    company: 'Google Inc',
    price: '$301.20',
    valueChange: '-101.50',
    balance: '$301.20',
    chartData: [80, 60, 70, 50, 30, 40, 20],
    trend: 'down',
  },
];

const chartOptions = {
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    x: {
      display: false,  
    },
    y: {
      display: false,  
    },
  },
  elements: {
    point: {
      radius: 0,  
    },
  },
  plugins: {
    tooltip: {
      enabled: false,  
    },
    legend: {
      display: false,  
    },
  },
};

const Watchlist = () => {
  const { theme } = useContext(ThemeContext);

  return (
    <div className={`watchlist-wrapper ${theme}`}>
      <div className="watchlist-main">
        <div className="watchlist-container">
          <div className="watchlist-header">
            <h2>Watchlist</h2>
            {/* <p>Update 20/07/2024 at 04:30 PM</p> */}
            <button className="add-watchlist-btn">+ Watchlist</button>
          </div>

          <table className="watchlist-table">
            <thead>
              <tr>
                <th>Stock Name</th>
                <th>Price</th>
                <th>Value</th>
                <th>Balance</th>
                <th>Chart</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {stockData.map((stock, index) => (
                <tr key={index}>
                  <td>
                    <div className="stock-info">
                      {stock.name === 'AAPL' && <FaApple className="stock-logo" />}
                      {stock.name === 'GOOGL' && <FaGoogle className="stock-logo" />}
                      <span>{stock.company}</span>
                    </div>
                  </td>
                  <td>{stock.price}</td>
                  <td className={stock.valueChange.startsWith('+') ? 'positive' : 'negative'}>
                    {stock.valueChange}
                    {stock.trend === 'up' ? <span className="trend-icon">↑</span> : <span className="trend-icon">↓</span>}
                  </td>
                  <td>{stock.balance}</td>
                  <td>
                    <div className="chart-container">
                      <Line
                        data={{
                          labels: Array(stock.chartData.length).fill(''),
                          datasets: [
                            {
                              data: stock.chartData,
                              borderColor: stock.trend === 'up' ? 'green' : 'red',
                              borderWidth: 2,
                              fill: true,
                              backgroundColor: stock.trend === 'up' ? 'rgba(0, 255, 0, 0.1)' : 'rgba(255, 0, 0, 0.1)',
                            },
                          ],
                        }}
                        options={chartOptions}
                      />
                    </div>
                  </td>
                  <td><button className="buy-btn">Buy</button></td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {/* Right panel displaying stock details with logo, company, and chart */}
        <div className="right-panel">
          <h3>Stock Details</h3>
          <ul className="stock-details-list">
            {stockData.map((stock, index) => (
              <li key={index} className="stock-details-item">
                <div className="stock-summary">
                  {stock.name === 'AAPL' && <FaApple className="stock-logo" />}
                  {stock.name === 'GOOGL' && <FaGoogle className="stock-logo" />}
                  <span className="company-name">{stock.company}</span>
                </div>
                <div className="chart-container-small">
                  <Line
                    data={{
                      labels: Array(stock.chartData.length).fill(''),
                      datasets: [
                        {
                          data: stock.chartData,
                          borderColor: stock.trend === 'up' ? 'green' : 'red',
                          borderWidth: 2,
                          fill: true,
                          backgroundColor: stock.trend === 'up' ? 'rgba(0, 255, 0, 0.1)' : 'rgba(255, 0, 0, 0.1)',
                        },
                      ],
                    }}
                    options={chartOptions}
                  />
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Watchlist;
