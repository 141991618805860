import React, { useContext, useState, useEffect } from "react";
import axios from "axios";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import {
  Card,
  CardContent,
  Typography,
  Tabs,
  Tab,
  CircularProgress,
  TextField,
  Button,
} from "@mui/material";
import { ArrowUpward, ArrowDownward, Business, Category, People, Event, LocationOn, Person, Link, Description, AccountBalanceWallet } from "@mui/icons-material";
import { ThemeContext } from '../../theme-context';
import { useParams } from "react-router-dom";
import Cookies from "js-cookie";

const companyDetails = {
  name: "Example Corp",
  ticker: "EXMPL",
  sector: "Technology",
  industry: "Software",
  employees: 1500,
  founded: 2000,
  headquarters: "San Francisco, CA",
  ceo: "Jane Doe",
  website: "www.example.com",
  description: "Example Corp is a leading provider of software solutions.",
};

const financialMetrics = {
  marketCap: "₹1.2B",
  peRatio: 25,
  dividendYield: "1.5%",
};

export default function StockChart() {
  const { theme } = useContext(ThemeContext);
  const [chartPeriod, setChartPeriod] = useState("30minute");
  const [stockData, setStockData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { instrumentToken } = useParams();
  const [quantity, setQuantity] = useState(0);

  useEffect(() => {
    const fetchStockData = async () => {
      const accessToken = Cookies.get('accessToken');
      try {
        setLoading(true);
        const response = await axios.get('https://backend.smartcapital.co.in/stock/historicalData', {
          headers: {
            'instrument-token': instrumentToken,
            'interval': chartPeriod, 
            'from': '2024-09-15+09:15:00',
            'to': '2024-10-17+09:20:00',
            'access-token' : accessToken,
          }
        });
        
        const formattedData = response.data.data.candles.map((candle) => {
          const timestamp = new Date(candle[0]).getTime() / 1000;
          return {
            time: timestamp,
            open: candle[1],
            high: candle[2],
            low: candle[3],
            close: candle[4],
          };
        });

        setStockData(formattedData);
      } catch (error) {
        setError("Failed to fetch stock data. Please try again.");
      } finally {
        setLoading(false);
      }
    };

    fetchStockData();
  }, [chartPeriod]);

  // Price Change Calculation for Interval
  const calculatePriceChange = (currentData) => {
    if (currentData.length < 2) return { priceChange: 0, percentageChange: 0 };

    const latestCandle = currentData[currentData.length - 1];
    const previousCandle = currentData[currentData.length - 2];

    const openPrice = previousCandle.open;
    const closePrice = latestCandle.close;

    const priceChange = closePrice - openPrice;
    const percentageChange = openPrice ? (priceChange / openPrice) * 100 : 0;

    return { priceChange, percentageChange };
  };

  const { priceChange, percentageChange } = calculatePriceChange(stockData);
  const chartColor = priceChange >= 0 ? "green" : "red";

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div
          style={{
            padding: "5px",
            border: "1px solid #ccc",
            borderRadius: "4px",
            backgroundColor: theme === "dark" ? "#333" : "#f5f5f5",
          }}
        >
          <p>{`Time: ${new Date(label * 1000).toLocaleTimeString()}`}</p>
          <p>{`Price: ₹${payload[0].value.toFixed(2)}`}</p>
        </div>
      );
    }
    return null;
  };

  const handleTabChange = (event, newValue) => {
    setChartPeriod(newValue);
  };

  const handleBuy = () => {
    // Implement buy logic here
    console.log(`Buying ${quantity} stocks.`);
  };

  const handleSell = () => {
    // Implement sell logic here
    console.log(`Selling ${quantity} stocks.`);
  };

  if (loading) {
    return (
      <div
        style={{
          minHeight: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: theme === "dark" ? "#121212" : "#f5f5f5",
        }}
      >
        <CircularProgress />
        <Typography variant="h6" style={{ marginLeft: "16px" }}>
          Loading Stock Data...
        </Typography>
      </div>
    );
  }

  if (error) {
    return (
      <div
        style={{
          minHeight: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: theme === "dark" ? "#121212" : "#f5f5f5",
        }}
      >
        <Typography variant="h6" color="error">
          {error}
        </Typography>
      </div>
    );
  }

  return (
    <div
      style={{
        minHeight: "100vh",
        backgroundColor: theme === "dark" ? "#121212" : "#f5f5f5",
        color: theme === "dark" ? "#ffffff" : "#000000",
        padding: "16px",
        transition: "background-color 0.3s",
      }}
    >
      <div style={{ display: "grid", gridTemplateColumns: "70% 30%", gap: "16px", marginTop: "16px" }}>
        {/* Stock Price Chart Card */}
        <Card style={{ backgroundColor: theme === "dark" ? "#181818" : "#fff", color: theme === "dark" ? "#fff" : "#000" }}>
          <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: "16px" }}>
            <div>
              <Typography variant="h5">{companyDetails.name} ({companyDetails.ticker})</Typography>
              <Typography variant="subtitle1">{companyDetails.sector} - {companyDetails.industry}</Typography>
            </div>
            <div style={{ textAlign: "right" }}>
              <Typography variant="h4">₹{stockData[stockData.length - 1]?.close.toFixed(2)}</Typography>
              <div style={{ display: "flex", alignItems: "center", fontSize: "1.5rem", color: chartColor }}>
                {priceChange >= 0 ? <ArrowUpward /> : <ArrowDownward />}
                {Math.abs(priceChange).toFixed(2)} ({percentageChange.toFixed(2)}%)
              </div>
            </div>
          </div>
          <CardContent style={{ paddingTop: "24px" }}>
            <Tabs value={chartPeriod} onChange={handleTabChange} variant="fullWidth" textColor="inherit" style={{ backgroundColor: theme === "dark" ? "#282828" : "#f5f5f5", borderRadius: '4px', marginBottom: '16px' }}>
              <Tab label="1 minute" value="minute" />
              <Tab label="3 minutes" value="3minute" />
              <Tab label="5 minutes" value="5minute" />
              <Tab label="10 minutes" value="10minute" />
              <Tab label="15 minutes" value="15minute" />
              <Tab label="30 minutes" value="30minute" />
              <Tab label="60 minutes" value="60minute" />
              <Tab label="1 day" value="day" />
            </Tabs>
            <div style={{ height: "400px" }}>
              <ResponsiveContainer width="100%" height="100%">
                <LineChart data={stockData}>
                  <XAxis dataKey="time" type="number" domain={['dataMin', 'dataMax']} tickFormatter={(value) => {
                    const date = new Date(value * 1000);
                    const hours = date.getHours().toString().padStart(2, "0");
                    const minutes = date.getMinutes().toString().padStart(2, "0");
                    const seconds = date.getSeconds().toString().padStart(2, "0");
                    return `${hours}:${minutes}:${seconds}`;
                  }} stroke={theme === "dark" ? "#ccc" : "#000"} />
                  <YAxis domain={["dataMin", "dataMax"]} stroke={theme === "dark" ? "#ccc" : "#000"} />
                  <Tooltip content={<CustomTooltip />} />
                  <Line type="monotone" dataKey="close" stroke={chartColor} strokeWidth={2} dot={false} />
                </LineChart>
              </ResponsiveContainer>
            </div>
          </CardContent>
        </Card>

        {/* Buy/Sell Card */}
        <Card
          style={{
            backgroundColor: theme === "dark" ? "#181818" : "#fff",
            color: theme === "dark" ? "#fff" : "#000",
          }}
        >
          <Typography
            variant="h5"
            style={{
              display: "flex",
              alignItems: "center",
              marginTop: "10px",
              marginLeft: "10px",
            }}
          >
            <AccountBalanceWallet style={{ marginRight: "8px" }} /> Buy/Sell Stock
          </Typography>
          <CardContent style={{ paddingTop: "24px" }}>
            <TextField
              label="Quantity"
              type="number"
              value={quantity}
              onChange={(e) => setQuantity(e.target.value)}
              fullWidth
              variant="outlined"
              margin="normal"
              InputLabelProps={{
                style: {
                  color: theme === "dark" ? "#fff" : "#000", 
                },
              }}
              InputProps={{
                style: {
                  borderColor: theme === "dark" ? "#fff" : "#000",
                },
              }}
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: theme === "dark" ? "#fff" : "#000", 
                  },
                  "&:hover fieldset": {
                    borderColor: theme === "dark" ? "#fff" : "#000",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: theme === "dark" ? "#fff" : "#000",
                  },
                },
              }}
            />
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleBuy}
                style={{ width: "48%" }}
              >
                Buy
              </Button>
              <Button
                variant="contained"
                color="secondary"
                onClick={handleSell}
                style={{ width: "48%" }}
              >
                Sell
              </Button>
            </div>
          </CardContent>
        </Card>
      </div>

      {/* Company Information and Financial Cards */}
      <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: "16px", marginTop: "16px" }}>
        <Card style={{ backgroundColor: theme === "dark" ? "#181818" : "#fff", color: theme === "dark" ? "#fff" : "#000" }}>
          <Typography variant="h5" style={{ display: "flex", alignItems: "center", marginTop: '10px', marginLeft: '10px' }}>
            <Business style={{ marginRight: "8px" }} /> Company Information
          </Typography>
          <CardContent style={{ paddingTop: "24px" }}>
            <dl style={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: "16px" }}>
              <div>
                <Typography variant="subtitle1" style={{ fontWeight: "bold", display: "flex", alignItems: "center" }}>
                  <Category style={{ marginRight: "8px" }} /> Sector
                </Typography>
                <Typography>{companyDetails.sector}</Typography>
              </div>
              <div>
                <Typography variant="subtitle1" style={{ fontWeight: "bold", display: "flex", alignItems: "center" }}>
                  <People style={{ marginRight: "8px" }} /> Employees
                </Typography>
                <Typography>{companyDetails.employees}</Typography>
              </div>
              <div>
                <Typography variant="subtitle1" style={{ fontWeight: "bold", display: "flex", alignItems: "center" }}>
                  <Event style={{ marginRight: "8px" }} /> Founded
                </Typography>
                <Typography>{companyDetails.founded}</Typography>
              </div>
              <div>
                <Typography variant="subtitle1" style={{ fontWeight: "bold", display: "flex", alignItems: "center" }}>
                  <LocationOn style={{ marginRight: "8px" }} /> Headquarters
                </Typography>
                <Typography>{companyDetails.headquarters}</Typography>
              </div>
              <div>
                <Typography variant="subtitle1" style={{ fontWeight: "bold", display: "flex", alignItems: "center" }}>
                  <Person style={{ marginRight: "8px" }} /> CEO
                </Typography>
                <Typography>{companyDetails.ceo}</Typography>
              </div>
              <div>
                <Typography variant="subtitle1" style={{ fontWeight: "bold", display: "flex", alignItems: "center" }}>
                  <Link style={{ marginRight: "8px" }} /> Website
                </Typography>
                <Typography>
                  <a href={companyDetails.website} target="_blank" rel="noopener noreferrer" style={{ color: theme === "dark" ? "#1e90ff" : "#007bff" }}>
                    {companyDetails.website}
                  </a>
                </Typography>
              </div>
            </dl>
            <div style={{ marginTop: "16px" }}>
              <Typography variant="subtitle1" style={{ fontWeight: "bold", display: "flex", alignItems: "center" }}>
                <Description style={{ marginRight: "8px" }} /> Company Description
              </Typography>
              <Typography>{companyDetails.description}</Typography>
            </div>
          </CardContent>
        </Card>

        {/* Financial Metrics */}
        <Card style={{ backgroundColor: theme === "dark" ? "#181818" : "#fff", color: theme === "dark" ? "#fff" : "#000" }}>
          <Typography variant="h5" style={{ display: "flex", alignItems: "center", marginTop: '10px', marginLeft: '10px' }}>
            <AccountBalanceWallet style={{ marginRight: "8px" }} /> Financial Metrics
          </Typography>
          <CardContent style={{ paddingTop: "24px" }}>
            <dl style={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: "16px" }}>
              {Object.entries(financialMetrics).map(([key, value]) => (
                <div key={key}>
                  <Typography variant="subtitle1" style={{ fontWeight: "bold" }}>
                    {key.replace(/([A-Z])/g, " $1").replace(/^./, (str) => str.toUpperCase())}
                  </Typography>
                  <Typography>{value}</Typography>
                </div>
              ))}
            </dl>
          </CardContent>
        </Card>
      </div>
    </div>
  );
}
